// @flow
import { useSelector } from "react-redux";
import { type ContextRouter } from "react-router";
import { CustomComponent } from "../Page";
import { fetchMarketingPageSaga } from "../Page/fetchData";

const prependTechnologyToPageName = (name) => {
  return `technology-${name}`;
};

const TechCareersPage = ({
  match: {
    params: { item },
  },
}: ContextRouter) => {
  const pageName = prependTechnologyToPageName(item || "default");
  const pageContent = useSelector((state) => state.customPages[pageName]);
  return <CustomComponent content={pageContent} />;
};

TechCareersPage.fetchData = fetchMarketingPageSaga;

export const UniversalRender = TechCareersPage;
